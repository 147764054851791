<template>
  <div>
      <h1>You have been logged out.</h1>
  </div>
</template>

<script>
export default {

  mounted(){
    this.$router.push({path: "/"})
  }

}
</script>

<style>

</style>